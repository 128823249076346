import React, {Fragment, useCallback, useContext, useMemo} from 'react';
import styled from 'styled-components';
import {Breakpoints, Context, NavActions} from '../../Contexts/AppContext';
import CustomNav from '../../Components/CustomNav';
import {ChevronLeft} from '../../Components/SvgIcon';
import {useDimension} from '../../Hooks/AppHooks';
import {AboutTheTun} from '../../Domain/Help';
import FixedRatioImage from '../../Components/FixedRatioImage';
import TUN_LOGO from '../../images/about/tun/TUN.jpg';

const AboutTheTunPage = props => {
  const app = useContext(Context);
  const {desktop, tablet} = useDimension();

  const bannerContext = useMemo(() => {
    if (!app.homeData) {
      return null;
    }
    return app.homeData.about.banners[1];
  }, [app.homeData]);

  if (!app.homeData) {
    return null;
  }

  return (
    <Fragment>
      {!desktop && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          }
          middle={<div>關於 TUN</div>}
        />
      )}
      <FixedRatioImage width="100%" ratio={1573 / 1340} image={TUN_LOGO} />
      <Wrapper>
        <div className="right">
          <div className="header">關於 TUN</div>
          {AboutTheTun.map((s, index) => (
            <section key={index} className="section">
              <div className="title">{s.title}</div>
              {s.data.map((c, idx) => (
                <Fragment key={idx}>
                  {c.context.map((ct, index) => (
                    <div key={index} className="content">
                      {ct}
                    </div>
                  ))}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                    }}>
                    {c.images.map((img, index) => {
                      return (
                        <FixedRatioImage
                          key={index}
                          width="100%"
                          mode="cover"
                          image={img.source}
                          ratio={img.ratio}
                          extraCss={{
                            marginBottom: 24,
                          }}
                          alt={`img-${index}`}
                        />
                      );
                    })}
                  </div>
                </Fragment>
              ))}
            </section>
          ))}
        </div>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  padding: 40px 50px;
  display: flex;
  & > .right {
    & > .header {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      margin-bottom: 32px;
    }
    & > .section {
      margin-bottom: 32px;
      & > .title {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        margin-bottom: 8px;
      }
      & > .content {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        margin: 12px 0;
        white-space: pre-line;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 20px;
    & > .right {
      & > .header {
        display: none;
      }
    }
  }
`;

export default AboutTheTunPage;
